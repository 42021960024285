<template>
	<div class="parkLocation">
		<!-- <div class="parkLocationTitle">园区选址</div> -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 30px">
      <el-breadcrumb-item :to="{ path: '/parkLocation' }">园区选址</el-breadcrumb-item>
    </el-breadcrumb>
		<div class="top">
			<div style="display: flex;">
				<el-input v-model="keyword" size="small" style="width: 399px;" placeholder="输入用户名称、公司、品牌进行搜索"></el-input>
				<el-button type="primary" icon="el-icon-search" size="small" style="margin-left:5px;" @click="search">搜索</el-button>
			</div>
			<div>
				<el-button type="primary" size="small" @click="addParkLocation">发布空间</el-button>
				<el-button type="primary" size="small" @click="addFiles">批量上传</el-button>
			</div>
		</div>
		<div class="table">
			<el-table :data="managers" border style="width: 100%">
				<el-table-column prop="space_name" align="center" label="空间名称"></el-table-column>
				<el-table-column prop="parkTypes" align="center" label="空间类型">
					<template slot-scope="scope">
						<div v-if="scope.row.parkTypes">
							<span v-for="item in scope.row.parkTypes" :key="item.id" style="margin-right: 5px;">{{item.title}}</span>
						</div>
					</template>
        </el-table-column>
				<el-table-column prop="position" align="center" label="地理位置">
					<template slot-scope="scope">
						<span style="margin-right: 5px;" v-if="scope.row.province&&scope.row.province.name">{{scope.row.province.name}}</span>
						<span style="margin-right: 5px;" v-if="scope.row.city&&scope.row.city.name">{{scope.row.city.name}}</span>
						<span style="margin-right: 5px;" v-if="scope.row.town&&scope.row.town.name">{{scope.row.town.name}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="address" align="center" label="详细地址"></el-table-column>
				<el-table-column prop="roles" align="center" label="剩余地址" width="100">
					<template slot-scope="scope">
						<span style="margin-right: 5px;cursor: pointer;text-decoration: underline;color:#409EFF" @click="goSurplus(scope.row)">
							{{scope.row.surplus_adr_count}}/{{scope.row.register_adr_count}}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="150">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="editParkLocation(scope.row)">编辑</el-button>
						<el-button type="primary" size="mini" @click="orderParkLocation(scope.row)">订单</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pages">
				<el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-count="pageCount" layout="total,prev,pager,next,jumper"
				 :total="total">
				</el-pagination>
			</div>
		</div>
    <!-- 批量添加文件 -->
    <importRegistration :display="display" @cancel="cancel" :isPark='true' />
	</div>
</template>
<script>
  import importRegistration from "@/components/importRegistration";

	export default {
		data() {
			return {
        id: '',
				keyword: '',
				managers: [],
				total: 0,
				page: 1,
				limit: 10,
				pageCount: Math.ceil(this.total / 10),
        display: false,
			}
    },
    components: {
      importRegistration
    },    
		mounted() {
			this.id = sessionStorage.organizationId;
			this.getParkLocation();
		},
		methods: {
			getParkLocation() {
				this.$http.get(this.URL.adminUrl.parkList, {
					params: {
						organ_id: this.id,
						keyword: this.keyword,
						page: this.page,
					}
				}).then((res) => {
					// console.log(res.data.data.data)
					this.managers = res.data.data.data;
					this.total = res.data.data.meta.total;
				})
			},
			search() {
				this.page = 1;
				this.getParkLocation();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getParkLocation();
      },
      // 发布空间跳转
			addParkLocation() {
				this.$router.push('parkRelease')
      },
      // 编辑跳转
			editParkLocation(row) {
				this.$router.push({
					name: 'parkEdit',
					params: {
						edit: true,
						id: row.id
					}
				});
			},
			// 剩余地址跳转
			goSurplus(row) {
				this.$router.push({
					name: 'parkSurplus',
					params: {
						data: row
					}
				})
			},
      // 订单
			orderParkLocation(row) {
				this.$router.push({
					name: 'parkOrder',
					params: {
						data: row
					}
				});
      },
      //添加文件
      addFiles() {
        this.display = true;
      },      
      // 控制上传弹窗
      uploadDisplayChange(msg) {
        this.uploadDisplay = msg;
      },
      cancel() {
        this.display = false;
        this.getParkLocation();
      },
		}
	}
</script>
<style scoped>
	.parkLocationTitle {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		font-size: 18px;
		height: 40px;
	}

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.table {
		margin-top: 30px;
	}

	.pages {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 50px 0;
	}
</style>
